
import * as R from 'ramda'
import { isShield } from '@/decorators/withoutCryptos'
import { defineComponent, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheTopic from '@/modules/community/communities/components/TheTopic.vue'
import { readMarketList } from '@/modules/market/market.api'

export default defineComponent(
  {
    name: 'Topic',
    components: { TheTopic, PageWithHeader },
    props: {
      topic: {
        type: String,
        required: true,
      },
    },
    setup (props) {
      const showTradeBtn = shallowRef(false)

      readMarketList().then(async resp => {
        const exist = R.find(R.propEq('code', props.topic), resp)
        const isCryptoCode = await isShield(props.topic)
        showTradeBtn.value = !!exist && !isCryptoCode
      })

      return {
        showTradeBtn,
      }
    },
  },
)
