<template>
  <Scroller @bottomTouched="loadMore" class="fill p-16">
    <ThePosts :content="loaded" no-pin />
  </Scroller>
</template>

<script lang="ts">
import { readPosts, Post, PostsResult } from '@/modules/community/communities/community.api'
import ThePosts from '@/modules/community/communities/components/ThePosts.vue'
import { defineComponent, watch } from 'vue'
import Scroller from 'common/providers/Scroller.vue'
import { RequestData } from '@/modules/community/communities/community'
import useLoadMore from 'common/hooks/useLoadMore'

export default defineComponent(
  {
    name: 'TheTopic',
    components: { Scroller, ThePosts },
    props: {
      topic: {
        type: String,
        required: true,
      },
    },
    setup (props) {
      const read = (data?: RequestData) =>
        readPosts(
          {
            ...data,
            pageSize: 10,
            label: props.topic,
          },
        )

      const { loaded, refresh, loadMore } = useLoadMore<Post, PostsResult>(
        read, 10, resp => resp.posts,
      )

      watch(() => props.topic, () => {
        refresh()
      }, { immediate: true })

      return {
        loadMore,
        loaded,
      }
    },
  },
)
</script>
