<template>
<PageWithHeader>
  <template #title>#{{topic}}</template>
  <template #right v-if="showTradeBtn">
    <router-link #="{ navigate }" :to="`/symbol/${topic}`" custom>
      <div class="df-middle df-center trade-btn" @click="navigate">
        <t path="tag_2" class="c-primary f-md">Trade Now</t>
      </div>
    </router-link>
  </template>
  <TheTopic :topic="topic" />
</PageWithHeader>
</template>

<script lang="ts">
import * as R from 'ramda'
import { isShield } from '@/decorators/withoutCryptos'
import { defineComponent, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheTopic from '@/modules/community/communities/components/TheTopic.vue'
import { readMarketList } from '@/modules/market/market.api'

export default defineComponent(
  {
    name: 'Topic',
    components: { TheTopic, PageWithHeader },
    props: {
      topic: {
        type: String,
        required: true,
      },
    },
    setup (props) {
      const showTradeBtn = shallowRef(false)

      readMarketList().then(async resp => {
        const exist = R.find(R.propEq('code', props.topic), resp)
        const isCryptoCode = await isShield(props.topic)
        showTradeBtn.value = !!exist && !isCryptoCode
      })

      return {
        showTradeBtn,
      }
    },
  },
)
</script>

<style scoped>
.trade-btn {
  border: 1px solid var(--color-primary);
  border-radius: 12px;
  padding: 2px 4px;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
