/**
* @description
*   ThePosts.vue of WeTrade
*/
<template>
  <Holder :content="content" :skeleton-repeat="2">
    <List
      :list-data="content" #="{ item }" pull-refresh @refresh="done => $emit('refresh', done)"
      :identify="v => v.postId"
    >
      <ThePostItem :post="item" :no-pin="noPin" class="mb-8" />
      <Line />
    </List>
  </Holder>
</template>

<script lang="ts">
import Line from '@/components/Line.vue'
import ThePostItem from '@/modules/home/components/items/ThePostItem.vue'
import Holder from '@/provider/Holder.vue'
import List from '@/components/AsyncNullableList.vue'

export default {
  name: 'ThePosts',
  components: { Line, ThePostItem, List, Holder },
  emits: ['refresh'],
  props: {
    content: {
      type: Array,
      require: true,
    },
    noPin: {
      type: Boolean,
    },
  },
}
</script>
